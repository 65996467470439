<template>
  <div class="rimconfig-breadcrumb">
    <v-container class="rimconfig-breadcrumb__container">
      <ul class="rimconfig-breadcrumb__list">
        <li
          v-for="(item, index) in breadcrumbItems"
          :key="`rimconfigBreadcrumb${item.key}${index}`"
          class="rimconfig-breadcrumb__item"
          @click="onClickBreadcrumbItem(item)"
        >
          {{ $t(item.label) }}
        </li>
      </ul>
    </v-container>
  </div>
</template>

<style lang="scss">
@import "@/styles/components/rimconfig-breadcrumb.scss";
</style>

<script>
import { mapState } from 'vuex';
export default {
  props: {
    breadcrumbItems: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    onClickBreadcrumbItem(item) {
      if (item.isCarSelector) {
        this.$emit('set-car-selector-step', item.step);
        return;
      } else {
        this.$emit(`on-click-${item.key}`);
      }
    },
  },
};
</script>