export const breadcrumbItems = [
  {
    key: 'brand',
    label: 'langkey.breadcrumb-brand',
    isCarSelector: true,
    step: 1,
  },
  {
    key: 'model',
    label: 'langkey.breadcrumb-model',
    isCarSelector: true,
    step: 2,
  },
  {
    key: 'type',
    label: 'langkey.breadcrumb-type',
    isCarSelector: true,
    step: 3,
  },
  {
    key: 'version',
    label: 'langkey.breadcrumb-version',
    isCarSelector: true,
    step: 4,
  },
];

export const sliderImages = [
  require('@/assets/images/Cover1.png'),
  require('@/assets/images/Cover1.png'),
  require('@/assets/images/Cover1.png'),
];