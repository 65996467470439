<template>
  <section class="rims-config-tpms">
    <rimconfig-breadcrumb
      :breadcrumb-items="breadcrumbItems"
      @set-car-selector-step="setCarSelectorStep"
    />
    <div
      class="content"
      :style="{ backgroundImage: `url(${require('@/assets/images/Cover1.png')})` }"
    >
      <div class="overlay">
        <div class="textbox">
          <div class="first">
            <h1>{{ $t('langkey.tpms-by-car') }}</h1>
          </div>
        </div>
        <base-rimconfig-car-selector
          is-tpms
          @select-version="onSelectCarVersion"
        />
      </div>
    </div>
  </section>
</template>

<style lang="scss">
@import '@/styles/pages/rims-config-tpms.scss';
</style>

<script>
import RimconfigBreadcrumb from '@/components/RimconfigBreadcrumb';
import { breadcrumbItems, sliderImages } from './configs.js';
import { mapState } from 'vuex';

export default {
  components: {
    RimconfigBreadcrumb,
  },
  data() {
    return {
      sliderOptions: {
        autoplay: true,
      },
      search: '',
      showSearchBar: false,
    };
  },
  computed: {
    ...mapState({
      step: (state) => state.rimconfigCarSelectorTpms.step,
    }),
    breadcrumbItems() {
      switch (this.step) {
      case 1:
        return breadcrumbItems.slice(0,1);
      case 2:
        return breadcrumbItems.slice(0,2);
      case 3:
        return breadcrumbItems.slice(0,3);
      case 4:
        return breadcrumbItems.slice(0,4);
      case 'kba-versions':
        return breadcrumbItems.slice(0,1).concat(breadcrumbItems.slice(3));
      default:
        return breadcrumbItems;
      }
    },
    sliderImages() {
      return sliderImages;
    },
  },
  mounted() {
    this.$store.dispatch('rimconfigCarSelectorTpms/setStep', this.step);
  },
  methods: {
    onSearch() {
      if (this.search === '') {
        this.showSearchBar = !this.showSearchBar;
      }
    },
    onSelectCarVersion(value) {
      this.$router.push(
        this.$i18nRoute({
          name: 'rims-config-tpms_carTag',
          params: {
            carTag: value.tag,
          },
        }),
      );
    },
    hideSearchBar() {
      if (this.search === '') {
        this.showSearchBar = false;
      }
    },
    clearSearch() {
      this.search = '';
    },
    setCarSelectorStep(step) {
      this.$store.dispatch('rimconfigCarSelectorTpms/setStep', step);
      this.$store.dispatch('rimconfigCarSelectorTpms/setSearchByKbaNumber', false);
    },
  },
};
</script>